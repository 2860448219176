import i18n from 'i18next';
import React from 'react';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter, HashRouter, Route, Routes, useSearchParams } from 'react-router-dom';

import translationCA from './locales/ca.json';
import translationCS from './locales/cs.json';
import translationDA from './locales/da.json';
import translationDE from './locales/de.json';
import translationEN from './locales/en.json';
import translationEN_US from './locales/en-US.json';
import translationES from './locales/es.json';
import translationES_MX from './locales/es-MX.json';
import translationFI from './locales/fi.json';
import translationFR from './locales/fr.json';
import translationIT from './locales/it.json';
import translationNL from './locales/nl.json';
import translationNO from './locales/no.json';
import translationPT from './locales/pt.json';
import translationPT_BR from './locales/pt-BR.json';
import translationSV from './locales/sv.json';
import LanguageDetector from './utils/lang.js';
import Homepage from './views/Homepage/Homepage';
import { PrivacyNotice,TermsOfUse } from './views/LegalContent';
import ReturnPrompt from './views/ReturnPrompt/ReturnPrompt';

function App() {
  _initInternationalization();
  
  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<UrlParameterRouter />}/>
        </Routes>
      </BrowserRouter>
      <HashRouter>
        <Routes>
          <Route exact path='/' element={<div />}/>
          <Route path='/terms-of-use' element={<TermsOfUse />}/>
          <Route path='/privacy-notice' element={<PrivacyNotice />}/>
        </Routes>
      </HashRouter>
    </main>    
  );
}

function UrlParameterRouter() {
  const [searchParams] = useSearchParams();
  
  const code = searchParams.get("code");
  const error = searchParams.get("error");
  
  if (code || error) {
    return (
      <div className="App">
        <ReturnPrompt oauthResponseParams={ searchParams } />
      </div>
    );
  }
  
  return (
    <Homepage oauthRequestParams={ searchParams } />
  );
}

function _initInternationalization() {
  const resources = {
    ca: {
      translation: translationCA
    },
    cs: {
      translation: translationCS
    },
    da: {
      translation: translationDA
    },
    de: {
      translation: translationDE
    },
    "en-US": {
      translation: translationEN_US
    },
    en: {
      translation: translationEN
    },
    "es-MX": {
      translation: translationES_MX
    },
    es: {
      translation: translationES
    },
    fi: {
      translation: translationFI
    },
    fr: {
      translation: translationFR
    },
    it: {
      translation: translationIT
    },
    nl: {
      translation: translationNL
    },
    no: {
      translation: translationNO
    },
    "pt-BR": {
      translation: translationPT_BR
    },
    pt: {
      translation: translationPT
    },
    sv: {
      translation: translationSV
    }
  };
  
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      resources
    })
  ;
}

export default App
